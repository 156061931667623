// FormConfig

<template>
  	<v-container fluid ma-0 pa-0>
        <v-row no-gutters>
        <v-col cols="12" class="pt-0">
        <v-card tile class="grey darken-3 overflow-hidden">
            <v-app-bar absolute dark dense color="blue-grey darken-2"
                scroll-target="#playground" elevate-on-scroll
            >
				<v-toolbar-title>Form Config {{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
				<v-btn small class="ml-3" color="success" :disabled="!isDirty" @click="saveData">Save</v-btn>
	  		</v-app-bar>
            <v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="700">
				<v-container fluid style="min-height: 700px;padding-top: 60px">
					<v-row>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="ColumnName" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Title" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Req" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Format" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Pos" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Width" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Type" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Opt" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
					</v-row>
					<v-row v-for="(item, index) in items" :key="index">
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0 text-black" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								:value="item.column_name" dense solo hide-details readonly
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.title" dense solo hide-details autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
						    <v-switch class="mt-1 ml-3"
                                v-model="item.req" dense solo hide-details color="blue-grey" @change="hasChanged"
                            ></v-switch>				
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                                <v-select class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
                                v-model="item.format" dense solo hide-details :items="foptions" @change="hasChanged"
                                ></v-select>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.pos" dense solo hide-details reverse autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.width" dense solo hide-details reverse autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                                <v-select class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
                                v-model="item.type" dense solo hide-details :items="toptions" @change="hasChanged"
                                ></v-select>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-tooltip v-if="item.type=='select'" left color="blue-grey darken-2"><template v-slot:activator="{ on, attrs }">
                            <v-btn class="ma-0" color="grey darken-2" v-bind="attrs" v-on="on"
                                @click="optionsEdit(item)"
                            ><v-icon>wysiwyg</v-icon></v-btn>
                            </template><span>{{ item.opt || '[.....]' }}</span></v-tooltip>
						</v-col>
					</v-row>
		  		</v-container>
			</v-sheet>
		</v-card>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
        </v-col>
        </v-row>
        <SelectOptions v-if="editOptions" :item="optionsItem" @cancelled="optionsCancelled()" @submitted="optionsSubmitted($event)" />
	</v-container>
</template>

<script>
import SelectOptions from './SelectOptions'
import { mapGetters } from 'vuex'
export default {
    name: 'FormConfig',
    components: {
        SelectOptions
    },
    props: {
        ctx: {
            type: Object, default: () => ({})
        },
    },
    data: () => ({
        loading: false,
        isDirty: false,
        items: [],
        foptions: JSON.parse('["default", "number", "date", "kw", "decimal", "euro", "proz"]'),
        toptions: JSON.parse('["default", "textarea", "number", "kw", "select", "lookup", "datepick", "calc"]'),
        optionsItem: {},
        editOptions: false,
    }),
    computed: {
        ...mapGetters({
            cfgforms: 'configs/getCfgForms',
        }),
		title: function() {
			return JSON.stringify(this.ctx)
		},
    },
    mounted() {
		this.fetchData()
    },
    methods: {
        fetchData() {
            this.loading = true
            let param = this.ctx
            let self = this
            this.$store.dispatch('configs/getCfgForms', param)
            .then((response) => {
                //console.log(this.cfgforms)
                this.items = JSON.parse(JSON.stringify(this.cfgforms))
                this.items.map(x=>x.format=x.format || 'default')
                this.items.map(x=>x.type=x.type || 'default')
                self.loading = false
            })
            .catch((error) => {
                console.log(error)
                self.loading = false
            });
        },
        hasChanged() {
            this.isDirty = true
            this.ctx.dirty = true
            //console.log(this.items)
        },
        saveData() {
            let param = {
                ctx: this.ctx,
                cfgforms: this.items,
            }
            this.$emit('dying', param)
            this.isDirty = false
        },
        optionsEdit(item) {
            //console.log('optionsEdit')
            this.optionsItem = item
            this.editOptions = true
        },
        optionsCancelled() {
            this.optionsItem = {}
            this.editOptions = false
        },
        optionsSubmitted(e) {
            //console.log(e)
            //this.items.filter(x => x.id === e.id).map(x => x.opt = e.opt);
            this.items.forEach((x) => { 
                if(x.id == e.id) {x.opt = e.opt} 
            })
            //console.log(this.items)
            this.optionsItem = {}
            this.editOptions = false
            this.isDirty = true
            this.ctx.dirty = true
        },
    },
    beforeDestroy() {
        let param = {
            ctx: this.ctx,
            cfgforms: this.items,
        }
        this.$emit('dying', param)
    },
    watch: {
    },
}
</script>

<style>
.text-black .v-text-field__slot input {
   color: #000 !important;
}
</style>